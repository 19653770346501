import styled from '@emotion/styled'

export const HeaderWrapper = styled.header({
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  height: '60px',
  justifyContent: 'space-between',
  padding: '1rem',
  width: '100%',
})

export const LogoWrapper = styled.img({
  maxHeight: '30px',
})
