import { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useStytchMember } from '@stytch/nextjs/b2b'

import { HeaderWrapper, LogoWrapper } from './style'
import { PrimaryButton } from '@components/core/buttons'
import { ThemeSize } from '@lib/theme'

const Header: FC = () => {
  const { isInitialized, member } = useStytchMember()
  const router = useRouter()

  return (
    <HeaderWrapper>
      {isInitialized && member ? (
        <Link href="/dashboard">
          <LogoWrapper src="/images/glisseo-logo-header.svg" alt="Glisseo" />
        </Link>
      ) : (
        <Link href="/">
          <LogoWrapper src="/images/glisseo-logo-header.svg" alt="Glisseo" />
        </Link>
      )}

      {isInitialized && member ? (
        <PrimaryButton
          onClick={() => router.push('/dasbhoard')}
          size={ThemeSize.Medium}
        >
          Dashboard
        </PrimaryButton>
      ) : (
        <PrimaryButton
          onClick={() => router.push('/login')}
          size={ThemeSize.Medium}
        >
          Login
        </PrimaryButton>
      )}
    </HeaderWrapper>
  )
}

export default Header
