import styled from '@emotion/styled'

export const ContentWrapper = styled.div({
  width: '100%',
})

export const PageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
})
