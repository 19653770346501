import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'
import { FlexAlign, FlexDirection, FlexJustify, FlexWrap } from '@lib/theme'

interface ContainerProps {
  desktopFlexDirecton?: FlexDirection
  desktopWrap?: boolean
  flexAlign?: FlexAlign
  flexDirection?: FlexDirection
  flexJustify?: FlexJustify
  largeDesktopFlexDirecton?: FlexDirection
  largeDesktopWrap?: boolean
  mobileFlexDirecton?: FlexDirection
  mobileWrap?: boolean
  tabletFlexDirecton?: FlexDirection
  tabletWrap?: boolean
  topMargin?: boolean
}

const Container = styled.div<ContainerProps>(
  {
    display: 'flex',
    margin: 'auto',
    padding: '0 15px',

    [`${mediaQueries.mobile}`]: {
      width: '100%',
    },
    [`${mediaQueries.tablet}`]: {
      width: '90%',
    },
    [`${mediaQueries.desktop}`]: {
      width: '80%',
    },
    [`${mediaQueries.largeDesktop}`]: {
      width: '70%',
    },
  },
  ({
    desktopFlexDirecton,
    desktopWrap = false,
    flexAlign,
    flexDirection = FlexDirection.Row,
    flexJustify,
    largeDesktopFlexDirecton,
    largeDesktopWrap = false,
    mobileFlexDirecton,
    mobileWrap = true,
    tabletFlexDirecton,
    tabletWrap = true,
    topMargin = false,
  }) => ({
    alignContent: flexAlign || 'inherit',
    alignItems: flexAlign || 'inherit',
    justifyContent: flexJustify || 'inherit',
    marginTop: topMargin ? '30px' : '0',

    [`${mediaQueries.mobile}`]: {
      flexDirection: mobileFlexDirecton || flexDirection,
      flexWrap: mobileWrap ? FlexWrap.Wrap : FlexWrap.NoWrap,
    },
    [`${mediaQueries.tablet}`]: {
      flexDirection: tabletFlexDirecton || flexDirection,
      flexWrap: tabletWrap ? FlexWrap.Wrap : FlexWrap.NoWrap,
    },
    [`${mediaQueries.desktop}`]: {
      flexDirection: desktopFlexDirecton || flexDirection,
      flexWrap: desktopWrap ? FlexWrap.Wrap : FlexWrap.NoWrap,
    },
    [`${mediaQueries.largeDesktop}`]: {
      flexDirection: largeDesktopFlexDirecton || flexDirection,
      flexWrap: largeDesktopWrap ? FlexWrap.Wrap : FlexWrap.NoWrap,
    },
  }),
)

export default Container
