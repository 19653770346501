import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'

export enum HeadingColor {
  Black = 'black',
  Primary = 'primary',
  White = 'white',
}

interface HeadingProps {
  color?: HeadingColor
}

const headingStyles = {
  cursor: 'default',
  fontWeight: 600,
  letterSpacing: '1px',
  lineHeight: '1.2',
  margin: 0,
  width: '100%',
}

export const H1 = styled.h1<HeadingProps>(
  {
    ...headingStyles,
    fontSize: '3rem',
  },
  ({ color, theme = anchoTheme }) => ({
    color: color ? theme.colors[color] : 'inherit',
  }),
)

export const H2 = styled.h2<HeadingProps>(
  {
    ...headingStyles,
    fontSize: '2.75rem',
  },
  ({ color, theme = anchoTheme }) => ({
    color: color ? theme.colors[color] : 'inherit',
  }),
)

export const H3 = styled.h3<HeadingProps>(
  {
    ...headingStyles,
    fontSize: '2.5rem',
  },
  ({ color, theme = anchoTheme }) => ({
    color: color ? theme.colors[color] : 'inherit',
  }),
)

export const H4 = styled.h4<HeadingProps>(
  {
    ...headingStyles,
    fontSize: '2rem',
  },
  ({ color, theme = anchoTheme }) => ({
    color: color ? theme.colors[color] : 'inherit',
  }),
)

export const H5 = styled.h5<HeadingProps>(
  {
    ...headingStyles,
    fontSize: '1.5rem',
  },
  ({ color, theme = anchoTheme }) => ({
    color: color ? theme.colors[color] : 'inherit',
  }),
)
