import { FC } from 'react'
import Module from '@components/core/module'
import { FlexJustify, ThemeColor } from '@lib/theme'
import Container from '@components/core/container'
import { H1 } from '@components/core/headings'
import { P } from '@components/core/paragraph'
import { PrimaryButton } from '@components/core/buttons'
import { useRouter } from 'next/router'

const Home: FC = () => {
  const router = useRouter()

  return (
    <>
      <Module color={ThemeColor.Gradient}>
        <Container flexJustify={FlexJustify.Center}>
          <img src="/images/glisseo-logo-stacked-white.svg" alt="Glisseo" />
        </Container>
        <Container
          flexJustify={FlexJustify.Center}
          desktopWrap
          largeDesktopWrap
          mobileWrap
          tabletWrap
          topMargin
        >
          <H1 style={{ textAlign: 'center', width: '100%' }}>
            Insurance, faster.
          </H1>
          <P style={{ textAlign: 'center' }}>
            Our platform gets you up and running with complicated insurance
            stuff in no time. Everything from custom complicated insurance stuff
            to other complex insurance use cases like insurance money stuff and
            insurance risk stuff.
          </P>
        </Container>
        <Container topMargin>
          <PrimaryButton
            color={ThemeColor.White}
            onClick={() => router.push('/login')}
            textColor={ThemeColor.Secondary}
          >
            Get started
          </PrimaryButton>
        </Container>
      </Module>
    </>
  )
}

export default Home
