import styled from '@emotion/styled'
import { rgba } from 'polished'
import glisseoTheme, { ThemeColor, ThemeSize } from '@lib/theme'

interface ButtonProps {
  color?: ThemeColor
  disabled?: boolean
  fullWidth?: boolean
  size?: ThemeSize
  textColor?: ThemeColor
}

const Button = styled.button<ButtonProps>(
  {
    borderBottomRightRadius: '5px',
    borderTopLeftRadius: '5px',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '1px',
    outline: 'none',
    textTransform: 'uppercase',
  },
  ({ disabled = false, fullWidth = false, size = ThemeSize.Large }) => ({
    borderRadius:
      size === ThemeSize.Large
        ? '30px'
        : size === ThemeSize.Medium
          ? '20px'
          : '15px',
    cursor: disabled ? 'default' : 'pointer',
    height:
      size === ThemeSize.Large
        ? '60px'
        : size === ThemeSize.Medium
          ? '40px'
          : '30px',
    opacity: disabled ? 0.35 : 1,
    padding:
      size === ThemeSize.Large
        ? '15px 30px'
        : size === ThemeSize.Medium
          ? '10px 20px'
          : '7.5px 15px',
    width: fullWidth ? '100%' : 'auto',
  }),
)

export const PrimaryButton = styled(Button)<ButtonProps>(
  {
    border: '2px solid',
    transition:
      'background-color .25s ease-in-out, border-color .25s ease-in-out, color .25s ease-in-out',
  },
  ({
    color = ThemeColor.Primary,
    disabled = false,
    textColor = ThemeColor.White,
    theme = glisseoTheme,
  }) => ({
    backgroundColor: theme.colors[color],
    borderColor: theme.colors[color],
    color: theme.colors[textColor],

    '&:hover': {
      backgroundColor: disabled
        ? theme.colors[color]
        : rgba(theme.colors[color], 0.8),
      borderColor: disabled
        ? theme.colors[color]
        : `${rgba(theme.colors[color], 0.8)}!important`,
    },
  }),
)

export const OutlineButton = styled(Button)<ButtonProps>(
  {
    backgroundColor: 'transparent',
    border: '2px solid',
  },
  ({
    color = ThemeColor.Primary,
    textColor = ThemeColor.Primary,
    theme = glisseoTheme,
  }) => ({
    borderColor: theme.colors[color],
    color: theme.colors[textColor],

    '&:hover': {
      borderColor: `${rgba(theme.colors[color], 0.65)}!important`,
      color: rgba(theme.colors[textColor], 0.65),
    },
  }),
)
