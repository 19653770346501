import { NextPage } from 'next'
import Page from '@components/page'
import Home from '@components/home'
import Head from 'next/head'

const HomePage: NextPage = () => {
  return (
    <Page noAuth>
      <Head>
        <title>Glisseo - Home</title>
      </Head>
      <Home />
    </Page>
  )
}

export default HomePage
