import { FC, useEffect, type PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import { useStytchMember } from '@stytch/nextjs/b2b'

import Footer from '@components/page/footer'
import Header from '@components/page/header'

import { ContentWrapper, PageWrapper } from './style'
import glisseoTheme from '@lib/theme'
import { ThemeProvider } from '@emotion/react'

interface PageProps extends PropsWithChildren {
  noAuth?: boolean
  noFooter?: boolean
  noHeader?: boolean
}

const Page: FC<PageProps> = ({
  children,
  noAuth = false,
  noFooter = false,
  noHeader = false,
}) => {
  const router = useRouter()
  const { isInitialized, member } = useStytchMember()

  useEffect(() => {
    if (!noAuth && isInitialized && !member && router) {
      router.push('/login')
    }
  }, [isInitialized, noAuth, member, router])

  return (
    <ThemeProvider theme={glisseoTheme}>
      <PageWrapper>
        {!noHeader && <Header />}

        <ContentWrapper>{children}</ContentWrapper>

        {!noFooter && <Footer />}
      </PageWrapper>
    </ThemeProvider>
  )
}

export default Page
